.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  height: 100vh;
  font-size: 62.5%;
}
#root {
  height: 100%;
  font-size: 16px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
textarea:focus,
input:focus {
  outline: none;
}
hr {
  border-color: var(--theme-primary-alpha-20);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-border {
  border-color: #3e68a8 !important;
}

.inputLabel {
  color: #3e68a8 !important;
}
.animate {
  transition: all 0.2s linear;
}
.helper-text {
  text-transform: initial;
  font-size: 1rem !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.smallPicker .MuiSelect-outlined {
  padding: 8.5px 14px !important;
}
/* .smallPicker .Mui-focused{
/* background-color: red !important; 
} */
.smallPicker .MuiOutlinedInput-root input {
  height: 0.4375em !important;
}
.smallPicker .MuiInputLabel-formControl {
  line-height: 28px !important;
  height: 24px;
  margin-top: -8px !important;
}
.smallPicker .Mui-focused,
.smallPicker .MuiFormLabel-filled {
  margin-top: 0px !important;
}
.MuiButton-textSecondary:hover {
  background-color: var(--theme-primary-alpha-60) !important;
  color: var(--theme-primary-text) !important;
}
.MuiButton-textSecondary {
  color: var(--theme-primary) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin: 0.4375em !important;
}
.MuiButton-containedPrimary {
  background-color: var(--theme-primary) !important;
  color: var(--theme-primary-text) !important;
}
.MuiButton-textPrimary {
  background-color: var(--theme-primary) !important;
  color: var(--theme-primary-text) !important;
  margin: 4px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.MuiLoadingButton-loadingIndicator {
  left: 16px !important;
}
.MuiButton-textPrimary:hover {
  opacity: 0.8;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-primary) !important;
}
.Mui-focused.MuiInputLabel-root {
  color: var(--theme-primary) !important;
}
.password .MuiOutlinedInput-input {
  padding-right: 70px;
}
.MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-ternary-text) !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
.MuiSelect-icon {
  color: var(--theme-ternary-text) !important;
}
.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-primary) !important;
}
.MuiInputLabel-root,
.MuiInputBase-input {
  color: var(--theme-ternary-text) !important;
}
.MuiAutocomplete-tag {
  color: var(--theme-ternary-text) !important;
  background-color: var(--theme-hover) !important;
}
.MuiAutocomplete-popper {
  text-transform: capitalize !important;
}
.MuiAutocomplete-tag .MuiSvgIcon-root.MuiChip-deleteIcon {
  color: var(--theme-ternary-text) !important;
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 10px 4px 7.5px 5px !important;
}
.MuiAutocomplete-input {
  font-size: 14px !important;
}
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
  margin-left: 30px;
}
.audioWave:first-child :first-child {
  height: 54px !important;
  overflow: hidden !important;
}
.size18 {
  font-size: 18px !important;
}
.react-flow__attribution {
  display: none;
}
.MuiButton-textInfo {
  margin: 0px 16px !important;
}
.MuiButton-textError {
  background-color: rgb(255, 60, 60) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: white !important;
}
.MuiButton-root.Mui-disabled {
  cursor: not-allowed !important;
  background-color: var(--theme-primary-alpha-40) !important;
  color: var(--theme-primary-text) !important;
}
.MuiMenu-list {
  background-color: var(--theme-ternary);
  color: var(--theme-ternary-text);
  border: 1px solid var(--theme-primary-alpha-40);
}
input.Mui-disabled {
  cursor: not-allowed !important;
  background-color: var(--theme-disabled);
  color: var(--theme-ternary-text) !important;
  -webkit-text-fill-color: var(--theme-ternary-text) !important;
}
.react-flow__edge-path,
.react-flow__connection-path {
  stroke: var(--theme-primary-alpha-40) !important;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  text-align: left !important;
  background-color: var(--theme-primary-alpha-5) !important;
  color: var(--theme-ternary-text) !important;
  border: 1px solid var(--theme-primary-alpha-60) !important;
}
.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible {
  box-shadow: none !important;
  background-color: var(--theme-primary-alpha-20) !important;
}
.MuiCircularProgress-colorSuccess {
  color: white !important;
}
::-webkit-scrollbar-track {
  background-color: var(--theme-primary-alpha-20);
}
::-webkit-scrollbar-thumb {
  background-color: var(--theme-primary-alpha-60);
}
.table-fix-head {
  overflow-y: auto;
  overflow-x: auto;
}
@media (min-width: 1300px) {
  .table-fix-head {
    overflow-x: hidden;
  }
}
/* * {
  outline: 1px solid #f00 !important;
} */

.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 6;
  background: var(--theme-primary);
  color: var(--theme-primary-text);
  /* background-color: var(--theme-table-header); */
}
.table-fix-head table {
  border-collapse: collapse;
}
.table-fix-head thead th svg {
  margin-left: 20px;
  color: var(--theme-primary-alpha-60);
}
.table-fix-head table tbody tr:hover {
  /* background-color: var(--theme-primary-alpha-20); */
}
.MuiSlider-track {
  background-color: var(--theme-primary) !important;
  border: none !important;
}
.MuiSlider-rail {
  background-color: var(--theme-primary-alpha-40) !important;
  border: none !important;
}
.MuiSlider-mark {
  background-color: var(--theme-primary) !important;
}
.Mui-disabled.MuiSlider-marked {
  opacity: 0.1;
}
.MuiSlider-markLabel {
  color: var(--theme-ternary-text) !important;
}
.MuiSlider-thumb {
  background-color: var(--theme-primary) !important;
  border: 2px solid var(--theme-primary-text) !important;
  width: 24px !important;
  height: 24px !important;
}

.css-80pr5n-MuiPaper-root {
  box-shadow: none !important;
}

.css-1h2ots1-MuiTableRow-root {
  background-color: rgba(26, 188, 156, 0.023) !important;
}
